import { useAppStore } from '@/stores/app';
import { SET_RESPONSIVE_VALUE } from '@/stores/app/actionTypes';
import { useResizeObserver } from '@vueuse/core';

export default () => {
    const appStore = useAppStore();
    const resizeCallback = () => {
        const ts = window.matchMedia('(max-width: 480px)').matches;
        const sm = window.matchMedia('(max-width: 640px)').matches;
        const md = window.matchMedia('(max-width: 768px)').matches;
        const lg = window.matchMedia('(max-width: 1024px)').matches;
        const xl = window.matchMedia('(max-width: 1280px)').matches;
        // 到临界点时才进行赋值，减少赋值频次
        if (appStore.responsive.ts !== ts || appStore.responsive.sm !== sm || appStore.responsive.md !== md || appStore.responsive.lg !== lg || appStore.responsive.xl !== xl) {
            appStore[SET_RESPONSIVE_VALUE]({ sm, md, lg, xl, ts });
        }
    };

    useResizeObserver(document.body, resizeCallback);
};
