import { handelLinkName } from '@/composables/views/tradingTools';
import { TradingToolButtonInterface, TradingToolInterface } from '@/model/views/member/tradingTools';
import { LANG_EN } from '@/utils/constant';
import { Router } from 'vue-router';
export const TRADING_TOOLS = 'trading-tools';

export const getTradingButtonList = (tradingTools: TradingToolInterface[], locale: string) => {
    const links = [] as Array<TradingToolButtonInterface>;
    if (tradingTools?.length) {
        tradingTools.forEach((v: TradingToolInterface) => {
            let buttonLink = '';
            const localButton = v.details.find((k) => k.language === locale)!;
            const enButton = v.details.find((val) => val.language === LANG_EN);
            if (enButton) buttonLink = handelLinkName(enButton.sub_tab_name);
            if (localButton) {
                links.push({
                    sub_tab_id: v.sub_tab_id,
                    link: `/${TRADING_TOOLS}/${buttonLink}`,
                    text: localButton.sub_tab_name,
                    buttonLink: buttonLink,
                    pop_is_show: v.pop_is_show,
                    allow_hide: v.allow_hide,
                    details: localButton,
                    defaultPath: changeRouter(`/${TRADING_TOOLS}/${buttonLink}`, localButton.iframe[0]?.url_name) ?? '',
                });
            }
        });
    }
    return links;
};

export const changeRouter = (subTabId: string, iframeId: string, router?: Router) => {
    let path = subTabId;
    if (iframeId) path = subTabId + '/' + iframeId;
    if (router) {
        router.push({ path: path });
    } else {
        return path;
    }
};
