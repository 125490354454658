export enum PayToAgreementStatus {
    STATUS_PENDING = 0,
    STATUS_ACTIVE = 1,
    STATUS_PAUSED = 2,
    STATUS_CANCELLED = 3,
}

export enum TradingAccountStatusEnum {
    EN = 1, // enabled
    RO = 2, // readonly
    DIS = 3, // disabled
    AC = 4, // archived
}
