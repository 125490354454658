import { getLocalStorage } from '@/utils/localStorage';
import type { PlausibleOptions } from './tracker';

/**
 * @internal
 */
type EventPayload = {
    readonly n: string;
    readonly u: Location['href'];
    readonly d: Location['hostname'];
    readonly r: Document['referrer'] | null;
    readonly w: Window['innerWidth'];
    readonly h: 1 | 0;
    readonly p?: string;
};

export type EventOptions = {
    /**
     * Callback called when the event is successfully sent.
     */
    readonly callback?: () => void;
    /**
     * Properties to be bound to the event.
     */
    readonly props?: { readonly [propName: string]: string | number | boolean };
};

/**
 * @internal
 * Sends an event to Plausible's API
 *
 * @param data - Event data to send
 * @param options - Event options
 */
export function sendEvent(eventName: string, data: Required<PlausibleOptions>, options?: EventOptions): void {
    const isLocalhost = /^localhost$|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*:)*?:?0*1$/.test(location.hostname) || location.protocol === 'file:';

    if (!data.trackLocalhost && isLocalhost) {
        return console.warn('[Plausible] Ignoring event because website is running locally');
    }

    try {
        if (window.localStorage.plausible_ignore === 'true') {
            return console.warn('[Plausible] Ignoring event because "plausible_ignore" is set to "true" in localStorage');
        }
    } catch (e) {
        null;
    }

    const defaultParams = {
        Application: 'CRM',
        Client: 'TMGM',
        Version: import.meta.env.PACKAGE_VERSION,
        'Relative Path': window.location.href.replace(window.location.origin + '/', ''),
    };
    const loginID = getLocalStorage('login_id');
    if (loginID && loginID !== 'undefined') {
        defaultParams['crm_user_id'] = loginID;
    }
    const optionsParams = options?.props || {};
    const resParams = { ...defaultParams, ...optionsParams };

    const payload: EventPayload = {
        n: eventName,
        u: data.url,
        d: window.vueConfig.VUE_APP_PLAUSIBLE_API_DOMAIN,
        r: data.referrer,
        w: data.deviceWidth,
        h: data.hashMode ? 1 : 0,
        p: JSON.stringify(resParams),
    };

    const req = new XMLHttpRequest();
    req.open('POST', `${data.apiHost}/api/event`, true);
    req.setRequestHeader('Content-Type', 'text/plain');
    req.send(JSON.stringify(payload));
    req.onreadystatechange = () => {
        if (req.readyState !== 4) return;
        if (options && options.callback) {
            options.callback();
        }
    };
}
