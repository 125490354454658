import { LoginTokenData, LoginUserInfoParamsInterface } from '@/model/utils/cookie';
import router from '@/router';
import { useAppStore } from '@/stores/app';
import { CHANGE_COMPANY, SET_LOGIN_USER, SET_MEMBER_INFO, SET_TODO_LIST, SET_TOKEN } from '@/stores/app/actionTypes';
import { useIbClientReportStore } from '@/stores/ibClientReport';
import { RESET_USER_VIEW, SET_EXPORT_ALL_LOADING } from '@/stores/ibClientReport/actionTypes';
import { useIbReportStore } from '@/stores/ibReport';
import { RESET_ALL_REPORT } from '@/stores/ibReport/actionTypes';
import { useLoginStore } from '@/stores/login';
import { useRegistrationStore } from '@/stores/registration';
import { SET_REGISTRATION_SEQUENCE } from '@/stores/registration/actionTypes';
import { useReportsStore } from '@/stores/reports';
import { RESET_VIEW } from '@/stores/reports/actionTypes';
import { removeCookie, setCookie } from './cookie';
import expiresCallback, { EXPIRE_TYPE, ExpireMap } from './expiresCallback';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from './localStorage';

const key = 'member';
export const tokenKey = `${key}_token`;
export const forceLogoutHash = '#forceLogout';
export const expireModalShowInterval = 2 * 60 * 1000;
const loginUserKey = `${key}_login_user`;

const everyTimeExec = (map: ExpireMap, now: number) => {
    const loginStore = useLoginStore();
    const localTokenData = getLocalStorage<LoginTokenData>(tokenKey);
    if (!localTokenData?.access_token) return forceLogout(true);

    // 倒计时的值需要和 localStorage 保存的值同步
    if (localTokenData) {
        setExpireCb(EXPIRE_TYPE.TOKEN_EXPIRE, { expire: localTokenData.expire });
        setExpireCb(EXPIRE_TYPE.SHOW_TOKEN_EXPIRE_DIALOG, { expire: localTokenData.countDownModalShowExpire });
    }

    // 同步完后倒计时的值可能会变，因此需要再实时获取一遍
    const showExpireModalTime = map.get(EXPIRE_TYPE.SHOW_TOKEN_EXPIRE_DIALOG)?.expire;
    const loginExpire = map.get(EXPIRE_TYPE.TOKEN_EXPIRE)?.expire;

    if (typeof showExpireModalTime === 'number' && typeof loginExpire === 'number') {
        // 只要在弹窗出现的时间段内就需要弹出弹窗
        if (now >= showExpireModalTime && now <= loginExpire && localTokenData?.needShowExpireModal) {
            if (loginStore.isExpireModalShow === false) loginStore.setExpireModalShow(true);
            loginStore.setExpireCountDown(Math.ceil((loginExpire - now) / 1000));
        } else {
            // 只要不在弹窗出现的时间段内就需要关闭弹窗
            if (loginStore.isExpireModalShow === true) loginStore.setExpireModalShow(false);
        }
    }
};

const { startCountDown, cancelAnimation, setExpireCb, getExpireCb, clearExpireCb } = expiresCallback({ everyTimeExec });
export { cancelAnimation, getExpireCb, setExpireCb };

const expireModalCb = () => {
    const loginStore = useLoginStore();
    setExpireCb(EXPIRE_TYPE.SHOW_TOKEN_EXPIRE_DIALOG, { expire: 0 });
    loginStore.setExpireModalShow(true);
};

export const forceLogout = (isNotShowForceLogoutMessage?: boolean) => {
    // 切换 Tab 时，requestFrame 会阻塞，此时在其他 tab 的 localStorage 延后时间再切换回来会导致在当前帧调用之前阻塞的回调函数直接退出
    // 而同步 localStorage 的数据会在下一帧进行，所以需要在当前帧不直接退出，等待下一帧的回调
    if ((getLocalStorage<LoginTokenData>(tokenKey)?.expire ?? 0) > Date.now()) return;
    const loginStore = useLoginStore();
    loginStore.setExpireModalShow(false);
    removeLoginInfo();
    loginStore.setIsForceLogout(!isNotShowForceLogoutMessage);
    router.push({ path: '/login', query: { redirect: router.currentRoute.value.fullPath } });
    return true;
};

export const startLoginCountDown = (logoutExpire?: number) => {
    let expire = 0;
    if (logoutExpire) {
        expire = logoutExpire;
    } else {
        const session_duration = getLocalStorage<LoginTokenData>(tokenKey)?.session_duration;
        expire = Date.now() + (session_duration ?? 0) * 1000;
    }

    startCountDown();
    // 同时设置弹窗出现和 logout 的倒计时
    setLoginExpireModalAndLogout(expire);
};

export function setLoginUserInfo(params: LoginUserInfoParamsInterface) {
    const appStore = useAppStore();
    const res = params.res;
    removeLocalStorage(`notification_${getLocalStorage('login_id')}`);
    removeLocalStorage(`trading_tools_popup_read_${getLocalStorage('login_id')}`);
    setCookie(loginUserKey, params.loginUser);

    const session_duration = JSON.parse(window.atob(res.access_token.split('.')[1])).session_duration;

    // session_duration 的单位为秒，每次请求都会根据这个值延长登录时间，staff 可以配置
    setLocalStorage(tokenKey, { access_token: res.access_token, session_duration, expire: Date.now() + session_duration * 1000, countDownModalShowExpire: 0, needShowExpireModal: false });

    startLoginCountDown();

    appStore[SET_TOKEN](res.access_token);
    appStore[SET_LOGIN_USER](params.loginUser);
}

export const requestExtendExpire = () => {
    const loginStore = useLoginStore();
    const tokenData = getLocalStorage<LoginTokenData>(tokenKey);

    const nextExpire = Date.now() + (tokenData?.session_duration ?? 0) * 1000;

    loginStore.setExpireModalShow(false);
    // 记录弹窗出现时间和登出时间
    setExpireCb(EXPIRE_TYPE.TOKEN_EXPIRE, { expire: nextExpire });
    const countDownModalShowExpire = nextExpire - expireModalShowInterval;
    setExpireCb(EXPIRE_TYPE.SHOW_TOKEN_EXPIRE_DIALOG, { expire: countDownModalShowExpire });
    setLocalStorage(tokenKey, { ...tokenData, expire: nextExpire, countDownModalShowExpire, needShowExpireModal: true });
};

function removeExportListTimeout() {
    const exportMember = window.exportMember;
    for (const key in exportMember) {
        if (Object.hasOwn(exportMember, key)) {
            const element = exportMember[key];
            clearTimeout(element);
        }
    }
    window.exportMember = {};
    const ibClientReportStore = useIbClientReportStore();
    ibClientReportStore[SET_EXPORT_ALL_LOADING]();
}

export function removeLoginInfo() {
    const ibReportStore = useIbReportStore();
    const reportsStore = useReportsStore();
    const ibClientReportStore = useIbClientReportStore();
    const appStore = useAppStore();
    const loginStore = useLoginStore();
    const registrationStore = useRegistrationStore();

    removeLocalStorage(`notification_${getLocalStorage('login_id')}`);
    removeLocalStorage(`trading_tools_popup_read_${getLocalStorage('login_id')}`);

    removeCookie(loginUserKey);
    // 停止倒计时
    cancelAnimation();
    // 移除所有倒计时设置的截止时间
    clearExpireCb();
    // 隐藏出现的倒计时弹窗

    loginStore.setExpireModalShow(false);
    removeLocalStorage(tokenKey);

    appStore[SET_TOKEN]('');
    appStore[SET_LOGIN_USER]('');
    appStore[SET_MEMBER_INFO]({});
    appStore[CHANGE_COMPANY]({});
    appStore[SET_TODO_LIST]([]);
    registrationStore[SET_REGISTRATION_SEQUENCE]([]);
    ibReportStore[RESET_ALL_REPORT]();
    reportsStore[RESET_VIEW]();
    ibClientReportStore[RESET_USER_VIEW]();
    removeExportListTimeout();
}

export const isTokenEnable = () => {
    const tokenData = getLocalStorage<LoginTokenData>(tokenKey);
    const now = Date.now();
    return tokenData?.access_token && tokenData?.expire > now;
};

const setLoginExpireModalAndLogout = (logoutExpire: number) => {
    setExpireCb(EXPIRE_TYPE.TOKEN_EXPIRE, { expire: logoutExpire, cb: forceLogout });
    setExpireCb(EXPIRE_TYPE.SHOW_TOKEN_EXPIRE_DIALOG, {
        expire: logoutExpire - expireModalShowInterval,
        cb: expireModalCb,
    });
};
