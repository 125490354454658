export const getLocalStorage = <T>(key: string): T | null => {
    let result = localStorage.getItem(key);
    try {
        result = JSON.parse(result!);
        return result as T | null;
    } catch (error) {
        return result as T | null;
    }
};

export const setLocalStorage = (key: string, data: any) => {
    let result = data;
    try {
        if (typeof data === 'object' && data !== null) {
            result = JSON.stringify(data);
        }
        localStorage.setItem(key, result);
    } catch (error) {
        localStorage.setItem(key, result);
    }
};

export const removeLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};
