<template>
    <el-dialog append-to-body destroy-on-close :close-on-click-modal="false" v-model="isDialogShow" width="480px" :show-close="false" class="el-dialog__tertiary el-dialog__base">
        <div class="mb-6">
            <div class="flex items-center justify-center">
                <el-icon :size="40" v-if="icon === IconType.SUCCESS">
                    <app-icon type="success" />
                </el-icon>
                <el-icon :size="40" v-if="icon === IconType.INFO">
                    <app-icon type="info" />
                </el-icon>
            </div>
            <p class="my-4 max-w-full text-center break-words font-medium text-mystery text-4xl leading-8">{{ title }}</p>
            <slot />
        </div>
        <slot name="buttons">
            <div class="flex items-center justify-center">
                <el-button :loading="cancelLoading" plain v-if="!onlyConfirm" @click="onHandleCancel" data-testid="new_account_dialog_yes">{{ cancelText ?? $t('rewards.cancel') }}</el-button>
                <el-button :disabled="confirmDisabled" :loading="confirmLoading" @click="onHandleConfirm" data-testid="new_account_dialog_cancel">{{ confirmText ?? $t('rewards.confirm') }}</el-button>
            </div>
        </slot>
    </el-dialog>
</template>
<script lang="ts">
export enum IconType {
    SUCCESS = 'success',
    INFO = 'info',
}
</script>
<script setup lang="ts">
import { $t } from '@/utils/i18n';
import { computed, ref } from 'vue';

interface Props {
    modelValue: boolean;
    title?: string;
    confirmText?: string;
    cancelText?: string;
    icon?: IconType;
    onlyConfirm?: boolean;
    confirmDisabled?: boolean;
    // We need to run the confirm handler in the dialog component to add a loading animation to the submit button
    confirmHandler?: () => void;
    cancelHandler?: () => void;
}
const props = withDefaults(defineProps<Props>(), {
    icon: IconType.INFO,
    onlyConfirm: false,
    title: '',
});
const emit = defineEmits(['update:modelValue']);
const confirmLoading = ref(false);
const cancelLoading = ref(false);
const isDialogShow = computed({
    get: () => props.modelValue,
    set: (val) => {
        emit('update:modelValue', val);
    },
});
const onHandleCancel = async () => {
    cancelLoading.value = true;
    if (props.cancelHandler) {
        await props.cancelHandler();
    }
    cancelLoading.value = false;
    isDialogShow.value = false;
};
const onHandleConfirm = async () => {
    confirmLoading.value = true;
    if (props.confirmHandler) {
        await props.confirmHandler();
    }
    confirmLoading.value = false;
    isDialogShow.value = false;
};
</script>
