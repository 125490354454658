import brokereeFollower from '@/assets/Icons/B-Follower.svg';
import brokereeProvider from '@/assets/Icons/B-Provider.svg';
import ibUser from '@/assets/Icons/IB-user.svg';
import informationIconRed from '@/assets/Icons/Information-icon-red.svg';
import informationIcon from '@/assets/Icons/Information-icon.svg';
import mamFollower from '@/assets/Icons/M-Follower.svg';
import mamMaster from '@/assets/Icons/M-Master.svg';
import QRCodeIcon from '@/assets/Icons/QR-code-icon.svg';
import assignUser from '@/assets/Icons/assign-user.svg';
import blueCheck from '@/assets/Icons/blue-check.svg';
import blueStar from '@/assets/Icons/blue-star.svg';
import branchRewardSparkles from '@/assets/Icons/branch-completed-sparkles.svg';
import branchRewardCircleCenter from '@/assets/Icons/branch-reward-circle-center.svg';
import branchHasNoProgress from '@/assets/Icons/branchHasNoProgress.svg';
import camera from '@/assets/Icons/camera.svg';
import cardActive from '@/assets/Icons/card-active.svg';
import cardNormal from '@/assets/Icons/card-normal.svg';
import circleBlueTop from '@/assets/Icons/circle-blue-top.svg';
import circleRedBottom from '@/assets/Icons/circle-red-bottom.svg';
import clockwise from '@/assets/Icons/clockwise.svg';
import competitionActions from '@/assets/Icons/competition-actions.svg';
import competitionCreate from '@/assets/Icons/competition-create.svg';
import competitionGrades from '@/assets/Icons/competition-grades.svg';
import competitionInfoOutline from '@/assets/Icons/competition-info-outline.svg';
import competitionInfo from '@/assets/Icons/competition-info.svg';
import competitionQualified from '@/assets/Icons/competition-qualified.svg';
import competitionSave from '@/assets/Icons/competition-save.svg';
import competitionUnqualifiedGrey from '@/assets/Icons/competition-unqualified-grey.svg';
import competitionUnqualified from '@/assets/Icons/competition-unqualified.svg';
import copy from '@/assets/Icons/copy.svg';
import counterclockwise from '@/assets/Icons/counterclockwise.svg';
import createIconLight from '@/assets/Icons/create-icon-light.svg';
import createIcon from '@/assets/Icons/create-icon.svg';
import deleteIcon from '@/assets/Icons/delete.svg';
import demoUser from '@/assets/Icons/demo-user.svg';
import disableBlueCheck from '@/assets/Icons/disable-blue-check.svg';
import documentNotUploaded from '@/assets/Icons/document-not-uploaded.svg';
import documentPending from '@/assets/Icons/document-pending.svg';
import documentRejected from '@/assets/Icons/document-rejected.svg';
import documentVerifiedYellow from '@/assets/Icons/document-verified-yellow.svg';
import documentVerified from '@/assets/Icons/document-verified.svg';
import downloadCSVDark from '@/assets/Icons/download-csv-dark.svg';
import downloadCSVGray from '@/assets/Icons/download-csv-gray.svg';
import downloadCSV from '@/assets/Icons/download-csv.svg';
import editIcon from '@/assets/Icons/edit.svg';
import errInfoOutline from '@/assets/Icons/error-info-outline.svg';
import exportCSV from '@/assets/Icons/export-csv.svg';
import externalLink from '@/assets/Icons/external-link.svg';
import faceFail from '@/assets/Icons/face-fail.svg';
import faceLoad from '@/assets/Icons/face-loading.svg';
import faceResultError from '@/assets/Icons/face-result-error.svg';
import faceResultSuccess from '@/assets/Icons/face-result-success.svg';
import faceSuccess from '@/assets/Icons/face-success.svg';
import fileMenu from '@/assets/Icons/file-menu.svg';
import file from '@/assets/Icons/file.svg';
import filter from '@/assets/Icons/filter.svg';
import fire from '@/assets/Icons/fire.svg';
import grayInformation from '@/assets/Icons/gray-information.svg';
import greenCheck from '@/assets/Icons/green-check.svg';
import informationColorIcon from '@/assets/Icons/info-colored.svg';
import info from '@/assets/Icons/info-colored2.svg';
import infoHint from '@/assets/Icons/info-hint.svg';
import infoOutline from '@/assets/Icons/info-outline.svg';
import ingot from '@/assets/Icons/ingot.svg';
import invitation from '@/assets/Icons/invitation.svg';
import listActive from '@/assets/Icons/list-active.svg';
import listNormal from '@/assets/Icons/list-normal.svg';
import loginEarth from '@/assets/Icons/login-earth.svg';
import mamUser from '@/assets/Icons/mam-user.svg';
import memberVerified from '@/assets/Icons/member-verified.svg';
import menuClientsReport from '@/assets/Icons/menu-clients-report.svg';
import menuCompetition from '@/assets/Icons/menu-competition.svg';
import menuDashboard from '@/assets/Icons/menu-dashboard.svg';
import menuDocument from '@/assets/Icons/menu-document.svg';
import menuDownload from '@/assets/Icons/menu-download.svg';
import menuFold from '@/assets/Icons/menu-fold.svg';
import menuFundingReport from '@/assets/Icons/menu-funding-report.svg';
import menuFunding from '@/assets/Icons/menu-funding.svg';
import menuIbProfile from '@/assets/Icons/menu-ib-profile.svg';
import menuIB from '@/assets/Icons/menu-ib.svg';
import menuMessage from '@/assets/Icons/menu-message.svg';
import menuOrdersReport from '@/assets/Icons/menu-orders-report.svg';
import menuPromotions from '@/assets/Icons/menu-promotions.svg';
import menuRebateReport from '@/assets/Icons/menu-rebate-report.svg';
import menuReports from '@/assets/Icons/menu-reports.svg';
import menuRewards from '@/assets/Icons/menu-rewards.svg';
import menuSignout from '@/assets/Icons/menu-signout.svg';
import menuSwitchMb from '@/assets/Icons/menu-switch-mb.svg';
import menuTradingTools from '@/assets/Icons/menu-trading-tools.svg';
import menuUnfold from '@/assets/Icons/menu-unfold.svg';
import Menu from '@/assets/Icons/menu.svg';
import messageReply from '@/assets/Icons/message-reply.svg';
import mobileFace from '@/assets/Icons/mobile-face.svg';
import number1 from '@/assets/Icons/number_1.svg';
import number2 from '@/assets/Icons/number_2.svg';
import number3 from '@/assets/Icons/number_3.svg';
import points from '@/assets/Icons/points.svg';
import promotionAccount from '@/assets/Icons/promotion-account.svg';
import promotionDeposit from '@/assets/Icons/promotion-deposit.svg';
import promotionPeriod from '@/assets/Icons/promotion-period.svg';
import promotionProgress from '@/assets/Icons/promotion-progress.svg';
import promotionStar from '@/assets/Icons/promotion-star.svg';
import promotionTable from '@/assets/Icons/promotion-table.svg';
import promotionTime from '@/assets/Icons/promotion-time.svg';
import promotionTimer from '@/assets/Icons/promotion-timer.svg';
import question from '@/assets/Icons/question.svg';
import rankingShare from '@/assets/Icons/ranking-share.svg';
import rebateCurrencyActive from '@/assets/Icons/rebate-c-active.svg';
import rebateCurrency from '@/assets/Icons/rebate-c.svg';
import referFriends from '@/assets/Icons/refer-friends.svg';
import referralLinksActionGray from '@/assets/Icons/referral-links-action-gray.svg';
import referralLinksAction from '@/assets/Icons/referral-links-action.svg';
import referredUser from '@/assets/Icons/referred-user.svg';
import refreshLoading from '@/assets/Icons/refresh-loading.svg';
import registerAdditionalInfo from '@/assets/Icons/register-additional-info.svg';
import registerConfiguration from '@/assets/Icons/register-configuration.svg';
import registerPersonalInfo from '@/assets/Icons/register-personal-info.svg';
import registerQuestionnaire from '@/assets/Icons/register-questionnaire.svg';
import registerVerification from '@/assets/Icons/register-verification.svg';
import rejected from '@/assets/Icons/rejected-icon.svg';
import removeItem from '@/assets/Icons/remove-item.svg';
import searchDark from '@/assets/Icons/search-dark.svg';
import searchLight from '@/assets/Icons/search-light.svg';
import searchMamDisabled from '@/assets/Icons/search-mam-disabled.svg';
import searchMam from '@/assets/Icons/search-mam.svg';
import searchWhite from '@/assets/Icons/search-white.svg';
import sendEmailDark from '@/assets/Icons/send-email-dark.svg';
import sendEmailGray from '@/assets/Icons/send-email-gray.svg';
import sendEmail from '@/assets/Icons/send-email.svg';
import themeSetting from '@/assets/Icons/setting.svg';
import shareGrade from '@/assets/Icons/share-grade.svg';
import share from '@/assets/Icons/share.svg';
import sortBottom from '@/assets/Icons/sort-bottom.svg';
import sortTop from '@/assets/Icons/sort-top.svg';
import starFull from '@/assets/Icons/star-full.svg';
import star from '@/assets/Icons/star.svg';
import statusRefresh from '@/assets/Icons/status-refresh.svg';
import success from '@/assets/Icons/success-colored.svg';
import Switch from '@/assets/Icons/switch.svg';
import thumbnail from '@/assets/Icons/thumbnail.svg';
import timer from '@/assets/Icons/timer.svg';
import transactionReportAction from '@/assets/Icons/transaction-report-action.svg';
import transferArrow from '@/assets/Icons/transfer-arrow.svg';
import transferInternal from '@/assets/Icons/transfer-internal.svg';
import transferSub from '@/assets/Icons/transfer-sub.svg';
import upload from '@/assets/Icons/upload.svg';
import verified from '@/assets/Icons/verified-icon.svg';
import viewCSVGray from '@/assets/Icons/view-csv-gray.svg';
import viewCSV from '@/assets/Icons/view-csv.svg';
import walletCreate from '@/assets/Icons/wallet-create.svg';
import warningSvg from '@/assets/Icons/warning-colored.svg';
import whiteCheck from '@/assets/Icons/white-check.svg';
import whiteCounterclockwise from '@/assets/Icons/white-counterclockwise.svg';
import whiteFileMenu from '@/assets/Icons/white-file-menu.svg';
import whiteStar from '@/assets/Icons/white-star.svg';
import whiteThumbnail from '@/assets/Icons/white-thumbnail.svg';
import zoomIn from '@/assets/Icons/zoom-in.svg';
import zoomOut from '@/assets/Icons/zoom-out.svg';

import * as allIcons from '@element-plus/icons-vue';
import * as _ from 'lodash-es';
import { defineComponent } from 'vue';
import './index.scss';

export interface IconProps {
    type: string;
}
export interface IconMap {
    [index: string]: any;
}

const internalIcons: IconMap = {
    disableBlueCheck,
    switch: Switch,
    menu: Menu,
    loginEarth,
    blueCheck: blueCheck,
    greenCheck: greenCheck,
    rejected: rejected,
    verified: verified,
    file: file,
    fileMenu: fileMenu,
    whiteFileMenu: whiteFileMenu,
    clockwise: clockwise,
    counterclockwise: counterclockwise,
    camera: camera,
    thumbnail: thumbnail,
    whiteThumbnail: whiteThumbnail,
    zoomIn: zoomIn,
    zoomOut: zoomOut,
    ibUser: ibUser,
    ingot,
    assignUser,
    referredUser,
    mamUser,
    demoUser,
    deleteIcon,
    editIcon,
    referFriends,
    documentVerified: documentVerified,
    documentRejected: documentRejected,
    documentNotUploaded: documentNotUploaded,
    documentPending: documentPending,
    documentVerifiedYellow,
    memberVerified: memberVerified,
    themeSetting: themeSetting,
    filter: filter,
    whiteStar: whiteStar,
    blueStar: blueStar,
    searchDark,
    searchLight,
    searchWhite,
    messageReply,
    registerAdditionalInfo: registerAdditionalInfo,
    registerConfiguration: registerConfiguration,
    registerPersonalInfo: registerPersonalInfo,
    registerVerification: registerVerification,
    registerQuestionnaire: registerQuestionnaire,
    informationIcon: informationIcon,
    informationIconRed: informationIconRed,
    informationColorIcon: informationColorIcon,
    transactionReportAction: transactionReportAction,
    downloadCSV: downloadCSV,
    downloadCSVDark,
    downloadCSVGray,
    removeItem: removeItem,
    sendEmail,
    sendEmailDark,
    sendEmailGray,
    viewCSV,
    viewCSVGray,
    statusRefresh: statusRefresh,
    infoHint: infoHint,
    exportCSV: exportCSV,
    upload,
    createIcon,
    createIconLight,
    whiteCheck,
    walletCreate,
    referralLinksAction,
    referralLinksActionGray,
    QRCodeIcon,
    whiteCounterclockwise,
    sortTop,
    sortBottom,
    circleBlueTop,
    circleRedBottom,
    faceResultSuccess,
    faceResultError,
    mobileFace,
    faceFail,
    faceLoad,
    faceSuccess,
    timer,
    promotionTime,
    promotionAccount,
    promotionDeposit,
    promotionProgress,
    promotionTable,
    promotionTimer,
    promotionPeriod,
    grayInformation,
    promotionStar,
    infoOutline,
    errInfoOutline,
    rebateCurrency,
    rebateCurrencyActive,
    invitation,
    searchMam,
    searchMamDisabled,
    mamMaster,
    mamFollower,
    info,
    success,
    warningSvg,
    copy,
    cardActive,
    cardNormal,
    listNormal,
    listActive,
    refreshLoading,
    branchRewardCircleCenter,
    branchRewardSparkles,
    branchHasNoProgress,
    star,
    starFull,
    share,
    points,
    menuDashboard,
    menuFunding,
    menuTradingTools,
    menuReports,
    menuCompetition,
    menuPromotions,
    menuRewards,
    menuIB,
    menuFold,
    menuUnfold,
    menuClientsReport,
    menuFundingReport,
    menuOrdersReport,
    menuRebateReport,
    menuIbProfile,
    menuDownload,
    menuDocument,
    transferInternal,
    transferSub,
    menuSwitchMb,
    transferArrow,
    fire,
    competitionCreate,
    competitionGrades,
    competitionQualified,
    competitionUnqualified,
    competitionUnqualifiedGrey,
    competitionActions,
    competitionInfo,
    competitionInfoOutline,
    competitionSave,
    menuSignout,
    menuMessage,
    number1,
    number2,
    number3,
    brokereeFollower,
    brokereeProvider,
    externalLink,
    question,
    rankingShare,
    shareGrade,
};
// internalIcons 下的图标命名，不要与 element-plus 的 el-icon 图标重名，具体看 standard.md

const externalIcons: IconMap = {};

_.forEach(allIcons, (value: any, key: string): void => {
    const name = _.kebabCase(key);
    externalIcons[name] = value;
});

export default defineComponent({
    props: {
        testId: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: 'middle',
        },
    },
    data() {
        return {
            internalIcons,
        };
    },
    computed: {
        isInternalIcon(): boolean {
            return !!internalIcons[this.type];
        },
    },
    render() {
        if (this.isInternalIcon) {
            return <i data-testid={this.testId} class={`app-icon app-icon__${this.size}`} v-html={internalIcons[this.type]}></i>;
        }

        if (externalIcons[this.type]) {
            return (
                <i data-testid={this.testId} class={`app-icon app-icon__${this.size} el-icon`}>
                    {externalIcons[this.type].render()}
                </i>
            );
        }

        return <i data-testid={this.testId}></i>;
    },
});
