interface ExpireData {
    expire: number;
    cb: Function;
}

export type ExpireMap = Map<string, ExpireData>;

interface Options {
    expireMap?: ExpireMap;
    everyTimeExec?: (map: ExpireMap, now: number) => void;
}

export enum EXPIRE_TYPE {
    TOKEN_EXPIRE = 'TOKEN_EXPIRE',
    SHOW_TOKEN_EXPIRE_DIALOG = 'SHOW_TOKEN_EXPIRE_DIALOG',
}

export default (options?: Options) => {
    const map = options?.expireMap ?? new Map<string, ExpireData>();
    let requestId: number | null = null;
    let isFinished = true;
    const startCountDown = () => {
        isFinished = false;
        let cbRes = false;
        const step = () => {
            const stepNow = Date.now();
            map.forEach((v, i) => {
                if (v.expire !== 0) {
                    if (v.expire < stepNow) {
                        // 当前帧的 callback 返回值为 true 时
                        // 不执行当前帧的必须执行函数
                        cbRes = v.cb();
                    }
                }
            });
            if (cbRes) return (isFinished = true);
            if (!isFinished) {
                options?.everyTimeExec?.(map, stepNow);
                requestId = requestAnimationFrame(step);
            }
        };
        requestId = requestAnimationFrame(step);
    };

    const cancelAnimation = () => {
        isFinished = true;
        if (requestId) cancelAnimationFrame(requestId);
    };

    const setExpireCb = (type: EXPIRE_TYPE, options: Partial<ExpireData>) => {
        const oldData = map.get(type);
        if (oldData) map.set(type, { ...oldData, ...options });
        else map.set(type, { expire: 0, cb: () => {}, ...options });
    };

    const getExpireCb = (type: EXPIRE_TYPE) => {
        return map.get(type);
    };

    const clearExpireCb = () => {
        map.clear();
    };

    return { startCountDown, cancelAnimation, setExpireCb, getExpireCb, clearExpireCb };
};
