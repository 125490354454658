import { useAppStore } from '@/stores/app';
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from 'vue';

export default function useResponsive() {
    const appStore = useAppStore();
    const { sm, md, lg, xl, ts } = toRefs(appStore.getResponsiveValue);

    return { sm, md, lg, xl, ts };
}

export function isPC() {
    const w = ref<number>(0);
    const pc = computed<boolean>(() => w.value > 1024);

    const setWidth = () => {
        w.value = document.documentElement.offsetWidth;
    };

    onMounted(() => {
        setWidth();

        window.addEventListener('resize', setWidth);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', setWidth);
    });

    return pc;
}
