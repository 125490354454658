import router from '@/router';
import { useAppStore } from '@/stores/app';
import { GET_COMPANY_INFO, GET_MEMBER_INFO, GET_MEMBER_PERMISSIONS, GET_TODO_LIST, SET_SCREEN_LOADING, SET_SYSTEM_TYPE } from '@/stores/app/actionTypes';
import { useRegistrationStore } from '@/stores/registration';
import { isTokenEnable, removeLoginInfo, setLoginUserInfo, startLoginCountDown } from '@/utils/login';
import * as _ from 'lodash-es';
import { requestStatistics } from '../../template';

const openLoading = () => {
    const appStore = useAppStore();
    appStore[SET_SCREEN_LOADING](true);
};
const closeLoading = () => {
    const appStore = useAppStore();
    appStore[SET_SCREEN_LOADING](false);
};
const routerTransition = (to, from) => {
    const fromPath = from.path;
    // registration page click menu without animation
    to.meta.transition = fromPath.includes('/member-register') ? '' : 'fade-transform';
};
const checkRegisterIsDone = () => {
    const registrationStore = useRegistrationStore();
    const userSequence = registrationStore.userSequence;
    if (userSequence === '' || userSequence === 'SP') {
        return true;
    }
    return false;
};
export default {
    authorize() {
        router.beforeEach(async (to, from, next) => {
            const appStore = useAppStore();
            openLoading();
            // need to refresh the company data after logging in
            let needRefreshCompany = false;
            const query = to.query;
            // Login user from the admin portal.
            if (query.access_token && query.expires_in && query.loginUser) {
                setLoginUserInfo({ res: query as any, loginUser: query.loginUser.toString() });
                appStore[SET_SYSTEM_TYPE]('Member_Portal');

                return next('/');
            }

            const isTokenEnabled = isTokenEnable();

            if (isTokenEnabled) {
                startLoginCountDown();
                if (to.path === '/login') {
                    return next({ path: to.query.redirect?.toString() || '/' });
                }
            }

            if (to.matched.some((route) => route.meta && route.meta.requiresAuth)) {
                if (!isTokenEnabled && from.path !== '/login') {
                    const nonRedirect = ['/', '/member-register', '/member-register-demo', '/retrieve-password', '/page404', '/register-error', '/mock-deposit', '/no-access'];
                    removeLoginInfo();
                    if (nonRedirect.includes(to.fullPath)) return next({ path: '/login' });
                    else {
                        return next({ path: `/login`, query: { redirect: to.fullPath } });
                    }
                }
                if (_.isEmpty(appStore.memberInfo)) {
                    needRefreshCompany = true;
                    await appStore[GET_MEMBER_INFO]();
                    await appStore[GET_MEMBER_PERMISSIONS]({
                        name: 'trading_competition',
                        payload: [27], //trading_competition_permissions,
                    });
                } else if (to.path.includes('tmgm-rewards')) {
                    // 实时更新 points 和 coupons 值，rewards tmgm-rewards 子路由都需要重新调用一次
                    //Todo: 优化member info api，将points拆开，具体看wallet.vue
                    appStore[GET_MEMBER_INFO]({
                        refresh: true,
                    });
                }
                await appStore[GET_TODO_LIST]({
                    refresh: to.name === 'dashboard',
                });
                if (appStore.memberInfo) {
                    const fundingPage = !appStore.memberInfo.funding_management && String(to.name)?.includes('member-funding');
                    const tradingToolsPage = !appStore.memberInfo.trading_tools && to.name === 'trading-tools';
                    const rewardsPage = !appStore.memberInfo.points_store && String(to.name)?.includes('rewards');
                    const competitionPage = (!appStore.memberInfo.trading_competition?.country_eligible || !appStore.memberPermissions.trading_competition?.trading_competition) && to.name === 'trading-competition';
                    const flatReportPage = !appStore.memberInfo.full_access && to.name === 'flat-reports';
                    const newBieTaskPage = !appStore.memberInfo.newbie_task && to.name === 'newbie-task';
                    const inviteFriends = !appStore.memberInfo.recommended_friends && to.name === 'invite-friends';
                    if (fundingPage || tradingToolsPage || rewardsPage || flatReportPage || inviteFriends || newBieTaskPage || competitionPage) return next({ path: '/page404' });
                }
                if (to.meta.category === 'register' && checkRegisterIsDone()) {
                    // Prevent route change if navigating from the dashboard to the same page
                    if (from.name === 'dashboard') {
                        closeLoading();
                        return next(false);
                    }
                    return next({ path: '/' });
                }
            }
            await appStore[GET_COMPANY_INFO]({
                route: to,
                refresh: needRefreshCompany || to.name === 'login',
            });
            return next();
        });
        router.afterEach((to, from) => {
            closeLoading();
            requestStatistics();
            routerTransition(to, from);
        });
        router.onError((err) => {
            console.error('Routing error: ', err);
        });
    },
};
