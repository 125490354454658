<template>
    <confirm-modal :confirm-handler="handleConfirm" :cancel-handler="handleCancel" v-model="loginStore.isExpireModalShow" :confirm-text="$t('global.continue_login')">
        <p>{{ $t('global.security_reminder', { time: loginStore.expireModalCountDown }) }}</p>
    </confirm-modal>
</template>
<script setup lang="ts">
import { getCompanyData } from '@/api/request';
import { useLoginStore } from '@/stores/login';
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage';
import { tokenKey } from '@/utils/login';
import ConfirmModal from '../Frame/ConfirmModal.vue';

const loginStore = useLoginStore();

const handleCancel = () => {
    const tokenData = getLocalStorage(tokenKey);
    if (tokenData) {
        setLocalStorage(tokenKey, { ...tokenData, needShowExpireModal: false });
    }
};

const handleConfirm = () => {
    getCompanyData({
        type: 2,
        lang: 'en',
        jurisdiction: 'VFSC',
    });
};
</script>
