import { useAppStore } from '@/stores/app';
import { $t } from '@/utils/i18n';
import { Directive } from 'vue';

/** 权限指令**/
/*
    1: live_ta_application
    2: demo_ta_application
    3: wallet_ta_application
    4: wallet_account
    5: promotions
    9: funding_management
    10: points_store
    11: deposit
    12: wallet_deposit
    13: wallet_points_deposit
    14: withdrawal
    15: wallet_withdrawal
    16: wallet_points_withdrawal
    17: transfer
    18: wallet_transfer
    19: wallet_points_transfer
    20: ib_promotions
    21: trading_tools
    22: trading_competition
*/
export const hasPermission = ({ name, key }) => {
    const appStore = useAppStore();
    const getPermission = appStore.memberPermissions[name];
    return getPermission && getPermission[key] === 1;
};

const loadingFun = (el: any, binding: any) => {
    el.style.position = 'relative';
    if (binding.value) {
        const wrapper = document.createElement('div');
        const loadingBox = document.createElement('div');
        const loadingIcon1 = document.createElement('div');
        const loadingIcon2 = document.createElement('div');
        const loadingIcon3 = document.createElement('div');
        const smallIcon1 = document.createElement('i');
        const smallIcon2 = document.createElement('i');
        const smallIcon3 = document.createElement('i');
        const loadingWord = document.createElement('p');
        const ellipsis = document.createElement('i');
        wrapper.className = 'loading-wrapper maskWrapper';
        loadingIcon1.className = 'loading';
        loadingIcon2.className = 'loading';
        loadingIcon3.className = 'loading';
        loadingWord.className = 'loading-text';
        loadingBox.className = 'loading-box loading-box__mini';
        ellipsis.className = 'ellipsis';
        loadingWord.innerHTML = $t('loading');
        ellipsis.innerHTML = '...';
        loadingWord.appendChild(ellipsis);
        loadingIcon1.appendChild(smallIcon1);
        loadingIcon2.appendChild(smallIcon2);
        loadingIcon3.appendChild(smallIcon3);
        loadingBox.appendChild(loadingIcon1);
        loadingBox.appendChild(loadingIcon2);
        loadingBox.appendChild(loadingIcon3);
        loadingBox.appendChild(loadingWord);
        wrapper.appendChild(loadingBox);
        el.appendChild(wrapper);
    } else {
        let loadingDom = null;
        const loadingChildNodes = [...el.childNodes];
        loadingChildNodes.forEach((val) => {
            if (val.className === 'loading-wrapper maskWrapper') loadingDom = val;
        });
        if (loadingDom) el.removeChild(loadingDom);
    }
};

export const tableLoading: Directive = {
    mounted: (el, binding) => {
        loadingFun(el, binding);
    },
    updated: (el, binding) => {
        if (binding.oldValue !== binding.value) {
            loadingFun(el, binding);
        }
    },
};
