const htmlTemplate = `<div class="screen-loading-wrapper">
<div class="loading-box">
    <div class="loading"><i></i></div>
    <div class="loading"><i></i></div>
    <div class="loading"><i></i></div>
</div>
</div>`;

const cssSource = `
<style>
.screen-loading-wrapper {
	display: flex;
	background: #f3faff;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
}
.screen-loading-wrapper .loading-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.screen-loading-wrapper .loading-box .loading {
    width: 50px;
    height: 15px;
    position: relative;
}
.screen-loading-wrapper .loading-box .loading > i {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 8px;
    border-radius: 4px;
}
.screen-loading-wrapper .loading-box > div:nth-of-type(1) > i {
    animation: loadcss 1s infinite linear;
    background-color: #296130;
}
.screen-loading-wrapper .loading-box > div:nth-of-type(2) > i {
    animation: loadcss 1s 0.2s infinite linear;
    background-color: #949598;
}
.screen-loading-wrapper .loading-box > div:nth-of-type(3) > i {
    animation: loadcss 1s 0.4s infinite linear;
    background-color: #ef4144;
}
@keyframes loadcss {
    0% {
        width: 50px;
   }
    50% {
        width: 25px;
   }
    100% {
        width: 50px;
   }
}
</style>`;

const requestStatistics = () => {
    let baiduConfig = window.vueConfig.VUE_APP_BAIDU_STATISTICS;
    if (!window.baiduId && baiduConfig) {
        if (baiduConfig) {
            baiduConfig = JSON.parse(baiduConfig);
            let config = baiduConfig.find((val) => {
                return window.location.origin.indexOf(val.domain) !== -1;
            });
            if (config) {
                window.baiduId = config.key;
            }
        }
    }
    if (window.baiduId) {
        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
        var hm = document.createElement('script');
        hm.src = `https://hm.baidu.com/hm.js?${window.baiduId}`;
        hm.id = 'baidu_tj';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
    }
};

export default { cssSource, htmlTemplate };
export { requestStatistics };
