import { defineStore } from 'pinia';

export const useLoginStore = defineStore('login', {
    state: () => ({
        isForceLogout: false,
        isExpireModalShow: false,
        expireModalCountDown: 0,
    }),

    actions: {
        setExpireModalShow(isShow: boolean) {
            this.isExpireModalShow = isShow;
        },
        setExpireCountDown(time: number) {
            this.expireModalCountDown = time;
        },
        setIsForceLogout(isForce: boolean) {
            this.isForceLogout = isForce;
        },
    },
});
