import { Objects } from '@/model/common';
import { reqJsonData } from './axios';

// 接口文档: https://github.com/found-solutions/CRM-Back-End/tree/develop

export function getTradingAccountList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/index', params, method: 'get' });
}
export function apiCreateTradingAccount<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/create', params, method: 'post', checkValue: true });
}
export function editTradingAccount<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/edit', params, method: 'post' });
}
export function cancelLeverageChange<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/leverageChanges/cancel', params, method: 'post' });
}
export function editTradingAccountLeverage<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/leverageChange', params, method: 'post' });
}
export function applyHub<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/applyHub', params, method: 'get' });
}
export function apiCheckTradingAccount<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/checkCreateAccountInfo', params, method: 'get' });
}
export function showMemberInfo<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/members/infoMember', params, method: 'get' });
}
export function editCurrency<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/editCurrency', params, method: 'put' });
}
export function getMemberCurrencyList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/currencies/index', params, method: 'get' });
}

// Brokeree
export function apiGetBrokereeTaRole() {
    type TradingAccount = {
        role: 1 | 2; // The backend only returns 1 or 2, but in reality, it has 0 (Regular TA), 1 (Provider TA), and 2 (Follower TA).
        trading_account_id: number;
    };
    type Result = { trading_tool_name: string; brokeree_url: string; trading_accounts: TradingAccount[] };

    return reqJsonData<Result>({ url: '/front/tradingAccounts/getBrokereeTaRole', params: {}, method: 'post' });
}

export function getBrokereeAccessToken(params: { lang: string; trading_account_id: number }) {
    return reqJsonData<{ brokeree_url: string }>({
        url: '/front/tradingAccounts/getBrokereeAccessToken',
        params,
        method: 'post',
    });
}

// demo ta
export function createDemoTradingAccount<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/demoTradingAccounts/create', params, method: 'post', checkValue: true });
}
export function getDemoTAConfig<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/demoTAInfo', params, method: 'get', accept: 'prs-v3' });
}

// todoList
export function getTodoList<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/members/toDoList', params, method: 'get' });
}

// promotion
export function getParticipatingPromotion<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/promotions/memberDashboardParticipantsList', params, method: 'get' });
}
export function promotionJoinAgain<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/promotions/joinAgain', params, method: 'post' });
}
export function promotionSort<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/promotions/sortParticipantsList', params, method: 'post' });
}
export function promotionRedeem<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/promotions/redeem', params, method: 'get' });
}

// wallet
export function createWallet<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/createWallet', params, method: 'post' });
}
export function walletIndex(params?: Objects) {
    return reqJsonData<ApiTA.WalletInfo>({ url: '/front/tradingAccounts/walletIndex', params, method: 'get' });
}
export function editWallet<T>(params?: Objects) {
    return reqJsonData<T>({ url: '/front/tradingAccounts/editWallet', params, method: 'put' });
}

//Member Upgrade
export function getMemberUpgradeConfig<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/memberUpgrade/configData', params, method: 'get' });
}
export function uploadMemberUpgrade<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/memberUpgrade/upload', params, method: 'post', isFormData: true, showProgressBar: true });
}
export function submitMemberUpgrade<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/memberUpgrade/submit', params, method: 'post' });
}
export function checkUpgradeAnswer<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/memberUpgrade/answer', params, method: 'post' });
}

//刷新题库
export function refreshQuestions<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/memberUpgrade/getMemberUpgradeQuestions', params, method: 'get' });
}
export function apiDemoTaDeposit<T>(params?: Objects) {
    return reqJsonData<T>({ url: 'front/tradingAccounts/demoTaDeposit', params, method: 'post' });
}
