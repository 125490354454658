import { Objects } from 'src/model/common';

// errorCode 报错可以新增报错类型，类型包括/success/info/warning/error，默认为error
export const codeList = [
    {
        code: 201,
        message: 'errorCode.hub_code_201',
    },
    {
        code: 202,
        message: 'errorCode.hub_code_202',
    },
    {
        code: 205,
        message: 'errorCode.hub_code_205',
    },
    {
        code: 301,
        message: 'errorCode.hub_code_301',
    },
    {
        code: 302,
        message: 'errorCode.hub_code_302',
    },
    {
        code: 303,
        message: 'errorCode.hub_code_303',
    },
    {
        code: 305,
        message: 'errorCode.hub_code_305',
    },
    {
        code: 306,
        message: 'errorCode.hub_code_306',
    },
    {
        code: 308,
        message: 'errorCode.hub_code_308',
    },
    {
        code: 999,
        message: 'errorCode.hub_code_999',
    },
    {
        code: 401,
        message: 'IB.token_has_expired',
    },
    {
        code: 10001,
        message: 'IB.given_data_was_invalid',
    },
    {
        code: 10011,
        message: 'errorCode.miss_system_config',
    },
    {
        code: 10002,
        message: 'IB.operation_failure',
    },
    {
        code: 10003,
        message: 'IB.file_type_error',
    },
    {
        code: 10004,
        message: 'IB.system_customization_error',
    },
    {
        code: 10005,
        message: 'IB.system_Verification_error',
    },
    {
        code: 10006,
        message: 'IB.system_Verification_failure',
    },
    {
        code: 10008,
        message: 'IB.need_refresh_data',
    },
    {
        code: 10009,
        message: 'errorCode.debounce_transaction',
    },
    {
        code: 10012,
        message: 'errorCode.exceed_rate_limit',
    },
    {
        code: 10013,
        message: 'errorCode.crm_under_maintenance_tip',
        type: 'warning',
    },
    {
        code: 10014,
        message: 'errorCode.function_disabled_tip',
    },
    {
        code: 10019,
        message: 'errorCode.link_invalid',
    },
    {
        code: 10024,
        message: 'errorCode.link_error',
    },
    {
        code: 110002,
        message: 'errorCode.redeem_failed',
    },
    {
        code: 20001,
        message: 'errorCode.channel_config_file_error',
    },
    {
        code: 20002,
        message: 'errorCode.payment_method_has_same_name',
    },
    {
        code: 20003,
        message: 'errorCode.channel_config_delete_error',
    },
    {
        code: 20004,
        message: 'errorCode.channel_config_edit_error',
    },
    {
        code: 20006,
        message: 'errorCode.bank_code_same_name',
    },
    {
        code: 20007,
        message: 'errorCode.default_channel_not_exist',
    },
    {
        code: 20008,
        message: 'errorCode.amount_not_meet_the_requirements',
    },
    {
        code: 20009,
        message: 'errorCode.csv',
    },
    {
        code: 20010,
        message: 'errorCode.changes_this_state_not_allowed',
    },
    {
        code: 20011,
        message: 'errorCode.no_default',
    },
    {
        code: 20012,
        message: 'errorCode.not_jurisdiction',
    },
    {
        code: 20015,
        message: 'errorCode.missing_required_field',
    },
    {
        code: 20020,
        message: 'errorCode.channel_name_exist',
    },
    {
        code: 20027,
        message: 'errorCode.payment_info_error',
    },
    {
        code: 20028,
        message: 'errorCode.card_verified_can_not_edit_delete',
    },
    {
        code: 20029,
        message: 'errorCode.card_number_wrong',
    },
    {
        code: 20030,
        message: 'errorCode.card_info_wrong',
    },
    {
        code: 40001,
        message: 'IB.the_token_has_been_blacklisted',
    },
    {
        code: 40002,
        message: 'IB.admin_user_not_exist',
    },
    {
        code: 40003,
        message: 'IB.incorrect_username_or_password',
    },
    {
        code: 40004,
        message: 'IB.admin_Group_id_already_exist',
    },
    {
        code: 40005,
        message: 'IB.no_mt4_account',
    },
    {
        code: 40006,
        message: 'IB.special_User_is_already_exist',
    },
    {
        code: 40007,
        message: 'IB.admin_group_not_exist',
    },
    {
        code: 40008,
        message: 'IB.special_user_disabled',
    },
    {
        code: 40009,
        message: 'errorCode.user_not_exist',
    },
    {
        code: 40010,
        message: 'IB.user_password_email_incorrect',
    },
    {
        code: 40011,
        message: 'IB.code_is_already_exist',
    },
    {
        code: 40012,
        message: 'IB.user_disables_deletion',
    },
    {
        code: 40013,
        message: 'IB.token_is_incorrect',
    },
    {
        code: 40014,
        message: 'IB.token_unauth',
    },
    {
        code: 40015,
        message: 'IB.token_grant_type',
    },
    {
        code: 40016,
        message: 'IB.token_is_invalid',
    },
    {
        code: 40017,
        message: 'IB.user_password_incorrect',
    },
    {
        code: 40018,
        message: 'errorCode.user_black_list',
    },
    {
        code: 40020,
        message: 'IB.user_rest_password_incorrect',
    },
    {
        code: 40021,
        message: 'IB.user_rest_password_email_expired',
    },
    {
        code: 40024,
        message: 'errorCode.user_has_no_permission',
    },
    {
        code: 40025,
        message: 'errorCode.ib_users_no_trading_account',
    },
    {
        code: 40028,
        message: 'errorCode.invalid_request_address',
    },
    {
        code: 40034,
        message: 'errorCode.admin_cannot_add_demo_account',
    },
    {
        code: 40040,
        message: 'errorCode.your_account_cannot_be_used',
    },
    {
        code: 40041,
        message: 'errorCode.user_has_no_operation_permissions',
    },
    {
        code: 40045,
        message: 'errorCode.the_email_in_black_list_unable_to_retrieve_password',
    },
    {
        code: 40047,
        message: 'errorCode.network_access_is_restricted',
    },
    {
        code: 40100,
        message: 'errorCode.authenticator_not_exist',
    },
    {
        code: 40101,
        message: 'errorCode.incorrect_password',
    },
    {
        code: 40102,
        message: 'errorCode.reset_fail',
    },
    {
        code: 40104,
        message: 'errorCode.authenticator_existed',
    },
    {
        code: 40105,
        message: 'errorCode.incorrect_password',
    },
    {
        code: 40106,
        message: 'errorCode.new_email_not_supported',
    },
    {
        code: 41000,
        message: 'errorCode.missing_registration_configuration_information',
    },
    {
        code: 41001,
        message: 'Tips.please_contact_the_administrator_to_register_account',
    },
    {
        code: 41002,
        message: 'errorCode.address_is_invalid',
    },
    {
        code: 41003,
        message: 'errorCode.country_has_added_configuration_information',
    },
    {
        code: 41004,
        message: 'errorCode.configuration_information_cant_be_deleted',
    },
    {
        code: 41005,
        message: 'errorCode.please_upload_file',
    },
    {
        code: 41006,
        message: 'errorCode.incorrect_user_name_or_email_authentication_code',
    },
    {
        code: 41007,
        message: 'errorCode.email_authentication_code_has_expired',
    },
    {
        code: 41008,
        message: 'errorCode.captcha_verify_fail',
    },
    {
        code: 41010,
        message: 'errorCode.registration_link_error',
    },
    {
        code: 41016,
        message: 'errorCode.source_error',
    },
    {
        code: 41035,
        message: 'Tips.you_cannot_apply_new_wallet_account_now',
    },
    {
        code: 41036,
        message: 'errorCode.account_has_exceeded',
    },
    {
        code: 41011,
        message: 'errorCode.register_config_changed',
    },
    {
        code: 41012,
        message: 'errorCode.miss_required_parameters',
    },
    {
        code: 41013,
        message: 'errorCode.password_is_empty',
    },
    {
        code: 41014,
        message: 'errorCode.sale_code_error',
    },
    {
        code: 41015,
        message: 'errorCode.upload_error',
    },
    {
        code: 41037,
        message: 'errorCode.exceeded_the_maximum_number_of_files',
    },
    {
        code: 41039,
        message: 'errorCode.email_format_error',
    },
    {
        code: 41040,
        message: 'errorCode.user_register_error',
    },
    {
        code: 41046,
        message: 'errorCode.less_than_the_minimum_number_of_files',
    },
    {
        code: 41053,
        message: 'errorCode.complete_required_questions',
    },
    {
        code: 42006,
        message: 'errorCode.not_receive_reward',
    },
    {
        code: 42007,
        message: 'errorCode.not_have_account',
    },
    {
        code: 42008,
        message: 'errorCode.user_not_participate_promotion',
    },
    {
        code: 42009,
        message: 'errorCode.activity_not_started',
    },
    {
        code: 42014,
        message: 'errorCode.received_all_reward',
    },
    {
        code: 42004,
        message: 'errorCode.client_can_not_withdrawal',
    },
    {
        code: 42022,
        message: 'errorCode.promotion_not_exist',
    },
    {
        code: 42023,
        message: 'errorCode.promotion_cannot_delete',
    },
    {
        code: 42024,
        message: 'errorCode.coupons_claim_not_successful',
    },
    {
        code: 42025,
        message: 'errorCode.promotion_exception',
    },
    {
        code: 42026,
        message: 'errorCode.promotion_ended',
    },
    {
        code: 42027,
        message: 'errorCode.promotion_redeem_repeatedly',
    },
    {
        code: 42028,
        message: 'errorCode.promotion_cant_adjust_order',
    },
    {
        code: 42029,
        message: 'errorCode.promotion_double_register',
    },
    {
        code: 42030,
        message: 'errorCode.promotion_have_withdrawn',
    },
    {
        code: 42031,
        message: 'errorCode.promotion_not_have_authorized',
    },

    {
        code: 42032,
        message: 'errorCode.promotion_end_cant_participate',
    },
    {
        code: 42034,
        message: 'errorCode.preview_key_expire',
    },
    {
        code: 42035,
        message: 'errorCode.redeem_failed',
    },
    {
        code: 42037,
        message: 'errorCode.participating_failed',
    },
    {
        code: 44015,
        message: 'errorCode.redemption_failed',
    },
    {
        code: 44016,
        message: 'errorCode.product_not_exist',
    },
    {
        code: 44017,
        message: 'errorCode.product_not_sale',
    },
    {
        code: 44018,
        message: 'errorCode.product_category_not_display',
    },
    {
        code: 44019,
        message: 'errorCode.product_not_redeem',
    },
    {
        code: 44020,
        message: 'errorCode.product_not_available',
    },
    {
        code: 44021,
        message: 'errorCode.system_busy',
    },
    {
        code: 44024,
        message: 'errorCode.amount_change',
    },
    {
        code: 44025,
        message: 'errorCode.coupon_not_available',
    },
    {
        code: 44027,
        message: 'errorCode.no_permission_order',
    },
    {
        code: 50001,
        message: 'errorCode.rebate_reques_time_limit',
    },
    {
        code: 60001,
        message: 'IB.trade_not_exist',
    },
    {
        code: 60002,
        message: 'errorCode.trans_edit_failed',
    },
    {
        code: 60004,
        message: 'errorCode.transactionTypeExist',
    },
    {
        code: 60005,
        message: 'errorCode.TransactionTypeNoEdit',
    },
    {
        code: 60006,
        message: 'errorCode.trans_approve_failed',
    },
    {
        code: 60003,
        message: 'errorCode.TransactionTypeNoDelete',
    },
    {
        code: 60009,
        message: 'errorCode.not_exist_default_exchange_rate',
    },
    {
        code: 60010,
        message: 'errorCode.internal_transfer_failed',
    },
    {
        code: 60011,
        message: 'errorCode.insufficient_withdrawal_amount',
    },
    {
        code: 60012,
        message: 'errorCode.no_permission',
    },
    {
        code: 60013,
        message: 'errorCode.error_deposit_request',
    },
    {
        code: 60016,
        message: 'Tips.you_cannot_apply_for_new_internal_transfer_now',
    },
    {
        code: 60017,
        message: 'Tips.you_cannot_apply_for_new_withdrawal_now',
    },
    {
        code: 60018,
        message: 'Tips.you_cannot_apply_for_new_deposit_now',
    },
    {
        code: 60019,
        message: 'errorCode.request_deposit_fund_failed',
    },
    {
        code: 60022,
        message: 'Tips.transfer_amount_error',
    },
    {
        code: 60026,
        message: 'Tips.email_account_not_match',
    },
    {
        code: 60027,
        message: 'Tips.account_not_transfer',
    },
    {
        code: 60028,
        message: 'Tips.you_cannot_apply_for_new_internal_transfer_sub',
    },
    {
        code: 60029,
        message: 'Tips.transfer_failed',
    },
    {
        code: 60030,
        message: 'Tips.same_user_transfer_sub',
    },
    {
        code: 60031,
        message: 'Tips.outstanding_order',
    },
    {
        code: 70001,
        message: 'IB.user_ID_is_required',
    },
    {
        code: 70002,
        message: 'IB.trading_account_ID_is_required',
    },
    {
        code: 70003,
        message: 'IB.file_does_not_exist',
    },
    {
        code: 70004,
        message: 'IB.rebate_report_already_exists',
    },
    {
        code: 70005,
        message: 'IB.the_nodes_created_are_duplicated',
    },
    {
        code: 70006,
        message: 'IB.failed_to_get_oss_url',
    },
    {
        code: 70007,
        message: 'IB.file_upload_failed',
    },
    {
        code: 70008,
        message: 'IB.file_deleted_failed',
    },
    {
        code: 70009,
        message: 'IB.agreement_file_name_cannot_be_repeated',
    },
    {
        code: 70010,
        message: 'IB.group_cannot_be_reused',
    },
    {
        code: 70011,
        message: 'IB.mt4_id_cannot_be_reused',
    },
    {
        code: 70015,
        message: 'errorCode.language_invaild',
    },
    {
        code: 70019,
        message: 'errorCode.agreement_not_exist',
    },
    {
        code: 70016,
        message: 'IB.protocol_has_been_applied',
    },
    {
        code: 70021,
        message: 'errorCode.file_lock_cannot_deleted',
    },
    {
        code: 70024,
        message: 'errorCode.lang_file_not_exist',
    },
    {
        code: 80001,
        message: 'IB.user_has_no_corresponding_children',
    },
    {
        code: 80002,
        message: 'IB.trading_account_does_not_exist',
    },
    {
        code: 80003,
        message: 'IB.the_password_format_is_incorrect',
    },
    {
        code: 80004,
        message: 'IB.password_setting_failed',
    },
    {
        code: 80005,
        message: 'IB.statusNoChange',
    },
    {
        code: 80006,
        message: 'IB.statusChangeFaild',
    },
    {
        code: 80007,
        message: 'IB.external_id_does_not_exist',
    },
    {
        code: 80008,
        message: 'IB.some_children_cannot_be_set',
    },
    {
        code: 80009,
        message: 'IB.customer_must_have_no_parent',
    },
    {
        code: 80010,
        message: 'IB.the_account_must_be_a_leaf_node',
    },
    {
        code: 80011,
        message: 'IB.account_not_exist_portal',
    },
    {
        code: 80012,
        message: 'IB.parent_account_not_exist',
    },
    {
        code: 80013,
        message: 'IB.trading_server_error',
    },
    {
        code: 80014,
        message: 'IB.the_currency_does_not_exist',
    },
    {
        code: 80015,
        message: 'IB.the_value_goes_wrong',
    },
    {
        code: 80016,
        message: 'IB.the_leverage_does_not_exist',
    },
    {
        code: 80017,
        message: 'IB.the_set_name_does_not_exist',
    },
    {
        code: 80018,
        message: 'IB.the_country_does_not_exist',
    },
    {
        code: 80019,
        message: 'IB.the_trading_server_group_does_not_exist',
    },
    {
        code: 80020,
        message: 'IB.the_trading_server_does_not_exist',
    },
    {
        code: 80023,
        message: 'errorCode.transactio_blocked',
    },
    {
        code: 80024,
        message: 'errorCode.insufficient_balance',
    },
    {
        code: 80025,
        message: 'IB.insufficient_credit',
    },
    {
        code: 80026,
        message: 'IB.group_saved_failed',
    },
    {
        code: 800029, // 这里是六位数
        message: 'errorCode.channel_not_exist',
    },
    {
        code: 80030,
        message: 'errorCode.chose_trading_server',
    },
    {
        code: 80032,
        message: 'errorCode.fund_name_exist',
    },
    {
        code: 80033,
        message: 'errorCode.exchange_rate_does_not_exit',
    },
    {
        code: 80035,
        message: 'errorCode.fund_not_allow',
    },
    {
        code: 80036,
        message: 'errorCode.payment_not_allow',
    },
    {
        code: 81000,
        message: 'errorCode.exists_error_tips',
    },
    {
        code: 80027,
        message: 'errorCode.missing_deposit_parameters',
    },
    {
        code: 80028,
        message: 'errorCode.bank_does_not_exist',
    },
    {
        code: 80029,
        message: 'errorCode.channel_does_not_exist',
    },
    {
        code: 80034,
        message: 'errorCode.third_party_failed',
    },
    {
        code: 80040,
        message: 'errorCode.not_auth_review_account',
    },
    {
        code: 80050,
        message: 'errorCode.exist_name',
    },
    {
        code: 80051,
        message: 'errorCode.exist_email',
    },
    {
        code: 80052,
        message: 'errorCode.unable_select_the_server',
    },
    {
        code: 80060,
        message: 'errorCode.unable_withdrawal',
    },
    {
        code: 80065,
        message: 'errorCode.not_exist_exchange_rate',
    },
    {
        code: 80066,
        message: 'errorCode.amount_not_enough_withdrawal',
    },
    {
        code: 80070,
        message: 'errorCode.request_trading_account_failed',
    },
    {
        code: 80075,
        message: 'errorCode.file_lose_config',
    },
    {
        code: 80076,
        message: 'errorCode.missing_order_type',
    },
    {
        code: 80079, // liveTA
        message: 'errorCode.beyond_the_limitation_of_ta',
    },
    {
        code: 80080, // demoTA,因为demoTA的errorCode都在白名单
        message: 'errorCode.beyond_the_limitation_of_ta',
    },
    {
        code: 80084,
        message: 'errorCode.email_invalid',
    },
    {
        code: 80086,
        message: 'errorCode.deposit_channel_disable',
    },
    {
        code: 80091,
        message: 'errorCode.mam_slave_account_only_deposit',
    },
    {
        code: 80092,
        message: 'errorCode.valid_trading',
    },
    {
        code: 80094,
        message: 'errorCode.mam_account_cannot_deposit_withdraw',
    },
    {
        code: 80098,
        message: 'errorCode.reports_cannot_generated_manual_accounts',
        type: 'warning',
    },
    {
        code: 80100,
        message: 'errorCode.invalid_product',
    },
    {
        code: 80101,
        message: 'errorCode.failed_please_contact_the_admin',
    },
    {
        code: 80105,
        message: 'errorCode.not_allowed_transfer_in_IB_TA',
    },
    {
        code: 80106,
        message: 'errorCode.not_allowed_deposit_to_IB_TA',
    },
    {
        code: 81002,
        message: 'errorCode.this_currency_disabled',
    },
    {
        code: 80104,
        message: 'errorCode.please_open_wallet',
    },
    {
        code: 80107,
        message: 'errorCode.operation_too_frequent_please_try_again_later',
    },
    {
        code: 80110,
        message: 'errorCode.notTransferToIb',
    },
    {
        code: 80601,
        message: 'errorCode.exchange_rate_invalid',
    },
    {
        code: 81005,
        message: 'errorCode.duplicate_trading_account_exist',
        type: 'warning',
    },
    {
        code: 81021,
        message: 'errorCode.apply_ta_limit',
        type: 'info',
    },
    {
        code: 81022,
        message: 'errorCode.you_have_submitted_the_request_please_wait',
    },
    {
        code: 90001,
        message: 'IB.permission_already_exists',
    },
    {
        code: 90002,
        message: 'IB.role_not_exist',
    },
    {
        code: 90003,
        message: 'IB.platform_not_exist',
    },
    {
        code: 90004,
        message: 'IB.user_not_have_right_permissions',
    },
    {
        code: 90005,
        message: 'IB.permission_not_right',
    },
    {
        code: 90008,
        message: 'errorCode.no_ib',
    },
    {
        code: 90009,
        message: 'errorCode.not_ib_portal',
    },
    {
        code: 80089,
        message: 'errorCode.no_point_account',
    },
    {
        code: 80109,
        message: 'funding.deposit_popup.payto.limit_message',
    },
    {
        code: 80113,
        message: 'funding.deposit_popup.payto.exist_message',
    },
    {
        code: 80114,
        message: 'funding.deposit_popup.payto.fail_message',
    },
    {
        code: 81006,
        message: 'errorCode.not_is_IB',
    },
    {
        code: 81007,
        message: 'errorCode.TA_not_available_to_check',
    },
    {
        code: 81008,
        message: 'Tips.you_cannot_apply_new_live_trading_account_now',
    },
    {
        code: 81011,
        message: 'errorCode.leverage_no_display_now',
    },
    {
        code: 81012,
        message: 'errorCode.account_no_rebate_report',
    },
    {
        code: 81013,
        message: 'errorCode.the_configure_not_exists',
    },
    {
        code: 82000,
        message: 'errorCode.demo_config_error',
    },
    {
        code: 82001,
        message: 'errorCode.not_create_demo',
    },
    {
        code: 82002,
        message: 'errorCode.demo_not_acount',
    },
    {
        code: 82003,
        message: 'errorCode.demo_deposit_error',
    },
    {
        code: 82004,
        message: 'errorCode.demo_trading_account_through_the_registration_process',
    },
    {
        code: 82005,
        message: 'errorCode.trading_server_type_is_unique',
    },
    {
        code: 82006,
        message: 'errorCode.points_currency_cannot_be_added',
    },
    {
        code: 82008,
        message: 'errorCode.unable_participate_promotion',
    },
    {
        code: 82010,
        message: 'errorCode.no_account_participate_promotion',
    },
    {
        code: 82011,
        message: 'errorCode.more_than_activation_time',
    },
    {
        code: 82013,
        message: 'errorCode.single_demo_deposit_limit',
    },

    {
        code: 82014,
        message: 'errorCode.balance_demo_deposit_limit',
    },

    {
        code: 82015,
        message: 'errorCode.disable_create_demo_ta',
    },
    {
        code: 84009,
        message: 'errorCode.referral_code_exist',
    },
    {
        code: 84010,
        message: 'errorCode.referral_link_no_create_permission',
    },
    {
        code: 84011,
        message: 'errorCode.referral_link_no_edit_permission',
    },
    {
        code: 85022,
        message: 'errorCode.the_account_is_archived',
    },
    {
        code: 87002,
        message: 'errorCode.invalid_code',
    },
    {
        code: 87003,
        message: 'errorCode.used_code',
    },
    {
        code: 87005,
        message: 'errorCode.send_error',
    },
    {
        code: 87006,
        message: 'errorCode.email_contact_admin',
    },
    {
        code: 87009,
        message: 'errorCode.invalid_code',
    },
    {
        code: 87010,
        message: 'errorCode.used_code',
    },
    // 单纯的没有权限创建demo ta的情况
    {
        code: 90011,
        message: 'Tips.you_cannot_apply_new_demo_trading_account_now',
    },
    {
        code: 90013,
        message: 'errorCode.is_mam_template_exists',
    },
    {
        code: 100004,
        message: 'errorCode.coupon_is_not_valid',
    },
    {
        code: 110003,
        message: 'errorCode.user_has_no_permission',
    },
] as Array<Objects>;
