import useResponsive from '@/composables/useResponsive';
import { TradingToolInterface } from '@/model/views/member/tradingTools';
import { MemberInfoInterface } from '@/model/vuex';
import { useAppStore } from '@/stores/app';
import { $t } from '@/utils/i18n';
import { getTradingButtonList } from '@/utils/tradingTool';
import * as _ from 'lodash-es';
import { computed } from 'vue';

export type MenuType = {
    key?: string;
    link: string;
    text: string;
    showText?: string;
    icon?: string;
    hide?: boolean;
    children?: MenuType[];
};

const ibMenu: MenuType[] = [
    {
        key: 'IBDashboard',
        link: '/ib/dashboard',
        text: 'menu.dashboard',
        icon: 'menuDashboard',
    },
    {
        key: 'ClientsReport',
        link: '/ib/clients-report',
        text: 'menu.clients_report',
        icon: 'menuClientsReport',
        children: [
            {
                key: 'MemberDetails',
                link: '/ib/clients-report/member-detail',
                text: 'menu.member_details',
            },
            {
                key: 'PendingCustomers',
                link: '/ib/clients-report/pending-customers',
                text: 'menu.pending_customers',
            },
        ],
    },
    {
        key: 'IBFunding',
        link: '/ib/funding',
        text: 'menu.ib_funding',
        icon: 'menuFundingReport',
    },
    {
        key: 'RebateReportManagement',
        link: '/ib/rebate-report-management',
        text: 'menu.rebate_report_management',
        icon: 'menuRebateReport',
        children: [
            {
                key: 'RebateReport',
                link: '/ib/rebate-report-management/rebate-reports',
                text: 'menu.rebate_report',
            },
            {
                key: 'FlatRebateReport',
                link: '/ib/rebate-report-management/flat-reports',
                text: 'menu.flat_rebate_report',
            },
        ],
    },
    {
        key: 'OrdersReportManagement',
        link: '/ib/orders-report-management',
        text: 'menu.orders_report_management',
        icon: 'menuOrdersReport',
        children: [
            {
                key: 'RecentOrders',
                link: '/ib/orders-report-management/recent-orders',
                text: 'menu.recent_orders',
            },
            {
                key: 'HistoricalOrders',
                link: '/ib/orders-report-management/historical-orders',
                text: 'menu.historical_orders',
            },
        ],
    },
    {
        key: 'IBProfile',
        link: '/ib/ib-profile',
        text: 'menu.ib_profile',
        icon: 'menuIbProfile',
        children: [
            {
                key: 'RebateNode',
                link: '/ib/ib-profile/rebate-node',
                text: 'menu.rebate_node',
            },
            {
                key: 'ReferralLinks',
                link: '/ib/ib-profile/referral-links',
                text: 'menu.referral_links',
            },
        ],
    },
];

const titleMenu: MenuType[] = [
    {
        key: 'login',
        link: '/login',
        text: 'menu.login',
        hide: false,
    },
    {
        key: 'register',
        link: '/register',
        text: 'menu.register',
        hide: false,
    },
    {
        key: 'retrieve-password',
        link: '/retrieve-password',
        text: 'menu.Retrieve_Password',
        hide: false,
    },
];

const headerMenu: MenuType[] = [
    {
        key: 'MessageCenter',
        link: '/user-account/message-center',
        text: 'menu.message_center',
    },
    {
        key: 'AccountSettings',
        link: '/user-account/account-settings',
        text: 'menu.account_settings',
    },
];

export default () => {
    const appStore = useAppStore();

    const { xl } = useResponsive();

    const memberInfo = computed<MemberInfoInterface>(() => appStore.memberInfo);
    const memberPermissions = computed(() => appStore.memberPermissions);

    const locale = computed((): string => appStore.locale);
    const tradingTools = computed((): Array<TradingToolInterface> => {
        return appStore.tradingTools.filter((tradingTool: TradingToolInterface) => tradingTool[xl.value ? 'status_mobile' : 'status']);
    });
    const tradingToolButtons = computed(() => getTradingButtonList(tradingTools.value, locale.value));

    const memberNormalMenu = computed<MenuType[]>(() => {
        return [
            {
                key: 'MemberDashboard',
                link: '/dashboard',
                text: 'menu.dashboard',
                icon: 'menuDashboard',
                hide: false,
            },
            {
                key: 'MemberFunding',
                link: '/funding',
                text: 'menu.funding',
                icon: 'menuFunding',
                hide: !memberInfo.value.funding_management,
                children: [
                    {
                        link: '/funding/deposit',
                        text: 'FundingManagement.type.deposit',
                    },
                    {
                        link: '/funding/withdrawal',
                        text: 'FundingManagement.type.withdrawal',
                    },
                    {
                        link: '/funding/transfer',
                        text: 'FundingManagement.type.transfer',
                    },
                ],
            },
            {
                key: 'TradingTools',
                link: '/trading-tools',
                text: 'menu.trading_tools',
                icon: 'menuTradingTools',
                hide: !memberInfo.value.trading_tools,
                children: tradingToolButtons.value?.map((i) => ({ link: i.link, text: '', showText: i.text })),
            },
            {
                key: 'Reports',
                link: '/reports',
                text: 'menu.reports',
                icon: 'menuReports',
                hide: false,
            },
            {
                key: 'Documents',
                link: '/documents',
                text: 'menu.documents',
                icon: 'menuDocument',
                hide: false,
            },
            {
                key: 'Downloads',
                link: '/downloads',
                text: 'menu.downloads',
                icon: 'menuDownload',
                hide: false,
            },
        ].filter((item) => !item.hide);
    });

    const memberMarketingMenu = computed(() => {
        return [
            {
                key: 'TradingCompetition',
                link: '/trading-competition',
                text: 'menu.competition',
                icon: 'menuCompetition',
                hide: !memberInfo.value.trading_competition?.country_eligible || !memberPermissions.value.trading_competition?.trading_competition,
            },
            {
                key: 'Promotions',
                link: '/promotions',
                text: 'menu.promotions',
                icon: 'menuPromotions',
                hide: !memberInfo.value.promotions,
            },
            {
                key: 'Rewards',
                link: '/tmgm-rewards',
                text: 'menu.rewards',
                icon: 'menuRewards',
                hide: !memberInfo.value.points_store,
            },
            {
                key: 'ReferFriends',
                link: '/refer-friends',
                icon: 'referFriends',
                text: 'refer_friend.refer_friends',
                hide: !memberInfo.value.newbie_task && !memberInfo.value.recommended_friends,
                children: [
                    {
                        key: 'newbieTask',
                        link: '/refer-friends/newbie-task',
                        text: 'refer_friend.beginner_task',
                        hide: !memberInfo.value.newbie_task,
                    },
                    {
                        key: 'inviteFriends',
                        link: '/refer-friends/invite-friends',
                        text: 'refer_friend.invite_friend',
                        hide: !memberInfo.value.recommended_friends,
                    },
                ],
            },
        ].filter((item) => !item.hide);
    });

    const allMenu = computed(() => [...titleMenu, ...memberNormalMenu.value, ...ibMenu, ...memberMarketingMenu.value, ...headerMenu]);

    const getRouterTitle = (router: any) => {
        if (!router.meta) return console.error('需传入路由实例');
        const { category } = router.meta;
        if (!category) return '';
        const activeRouter = allMenu.value.find((val) => val.key === category);
        return activeRouter?.text;
    };

    const setDocumentTitle = (route: any) => {
        const appStore = useAppStore();
        const companyList = appStore.companyList;
        const routerTitle = getRouterTitle(route);
        if (!_.isEmpty(companyList)) {
            if (typeof companyList.web_title !== 'undefined') {
                document.title = routerTitle ? `${$t(routerTitle)} / ${companyList.web_title}`.replace('<br>', ' ') : companyList.web_title;
            }
        } else {
            document.title = routerTitle ? `${$t(routerTitle)}`.replace('<br>', ' ') : 'CRM';
        }
    };

    return {
        memberNormalMenu,
        memberMarketingMenu,
        ibMenu,
        allMenu,
        setDocumentTitle,
    };
};
