import { $dayjs } from '@/plugins/day';
import Cookie from 'js-cookie';

function getExpiresTime(second: number) {
    return $dayjs().add(second, 'second').$d;
}

export function setCookie(name: string, value: any, second?: number | string) {
    second = Number(second);
    const expires = { expires: second ? getExpiresTime(second) : new Date('2099-01-01') };
    if (!import.meta.env.DEV) {
        return Cookie.set(name, value, { secure: true, sameSite: 'Lax', ...expires });
    } else {
        return Cookie.set(name, value, expires);
    }
}

export function getLang() {
    return Cookie.get(`member_locale`);
}

export function setLang(value: string) {
    return setCookie(`member_locale`, value);
}

export function getCookie(name: string) {
    return Cookie.get(name);
}

export function removeCookie(name: string) {
    return Cookie.remove(name);
}
